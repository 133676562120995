import ColouredBorderBox from "@Components/ColouredBorderBox";
import OpenInNew from "@Components/Icons/OpenInNew";
import TariffTable from "@SmartComponents/TenderResponses/PagePopup/Summary/Accordion/TariffTable";
import React, { Fragment } from "react";
import OOBCosts from "./OOBCosts";
import Button from "@Components/Button";
import ButtonLink from "@Components/ButtonLink";
import HealthScore from "@Components/HealthScore";
import HoverTooltip from "@Components/HoverTooltip";
import Arrow from "@Components/Icons/Arrow";
import Close from "@Components/Icons/Close";
import Score from "@Components/Icons/Score";
import Convenience from "@Components/Icons/Scores/Convenience";
import CustomerService from "@Components/Icons/Scores/CustomerService";
import Price from "@Components/Icons/Scores/Price";
import Signal from "@Components/Icons/Scores/Signal";
import Tariff from "@Components/Icons/Scores/Tariff";
import TopRated from "@Components/Icons/TopRated";
import getBrokerName from "@Helpers/GetBrokerName";
import GetPath from "@Helpers/GetPath";
import { SocialEnterpriseLimitation } from "@Shared/Entities/Supplier/enums";
import { Effected, platformToNetwork, TermLength, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { format } from "@Shared/Helpers/Money";
import { getIconFromNetwork, getIconFromPlatform, } from "@Shared/Helpers/Network";
import AdditionalInfo from "@SmartComponents/TenderResponses/PagePopup/Summary/AdditionalInfo";
import Warning from "@SmartComponents/TenderResponses/Warning";
import OfcomPopup from "./OfcomPopup";
import Factory from "@Shared/Factory";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import { SUPPLIER_RESPONSE } from "@Shared/Paths/Admin";
import Accordion from "./Accordion";
import accordionStyles from "./Accordion/index.module.scss";
import Bundles from "./Bundles";
import styles from "./index.module.scss";
export default class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyPoints: true,
            additionalInfoOpen: false,
            openOOB: false,
            openBundles: [],
            shownTariff: !props.comparison,
            openOfcom: false,
        };
    }
    openAdditionalInfo = () => {
        this.setState({
            additionalInfoOpen: true,
        });
    };
    closeAdditionalInfo = () => {
        this.setState({
            additionalInfoOpen: false,
        });
    };
    closeOfcom = () => {
        this.setState({
            openOfcom: false,
        });
    };
    openOOB = () => {
        this.setState({
            openOOB: true,
            openBundles: [],
        });
    };
    closeOOB = () => {
        this.setState({
            openOOB: false,
        });
    };
    openBundles = (bundles) => {
        this.setState({
            openBundles: bundles,
            openOOB: false,
        });
    };
    openSignalPopup = () => {
        this.setState({
            openOfcom: true,
            openOOB: false,
            openBundles: [],
        });
    };
    closeBundles = () => {
        this.setState({
            openBundles: [],
        });
    };
    getNetworkIcon = (platform) => {
        const Icon = getIconFromPlatform(platform);
        if (Icon === undefined) {
            return;
        }
        return React.createElement(Icon, { size: "4.5rem" });
    };
    getCostPerGB = () => {
        const costPerGB = this.props.response.meta.costPerGB;
        return format(costPerGB);
    };
    toggleKeyPoints = () => {
        if (!this.props.comparison) {
            return;
        }
        const { keyPoints } = this.state;
        this.setState({
            keyPoints: !keyPoints,
        });
    };
    render() {
        const { proposal } = this.props.response;
        const details = [
            {
                title: "If the customer does not take or renew another non-mobile product, what impact will this have on the customer cost or GP?",
                field: Effected.SpecificChangesOrRequirements,
            },
            {
                title: `What is the anticipated effect of not achieving the minimum contract spend of ${format(proposal.get(Effected.MinimumSpend), 0)}?`,
                field: Effected.ReducedEffect,
            },
            {
                title: "Additional details on leasing",
                field: Effected.LeasingNotes,
            },
            {
                title: "Additional details on devices",
                field: Effected.ExtraDeviceDetails,
            },
            {
                title: "Additional details on network",
                field: Effected.AdditionalNetworkInfo,
            },
            {
                title: "Additional details on additional lines",
                field: Effected.AdditionalLinesInfo,
            },
            {
                title: "Alternative MDM solution",
                field: Effected.AlternativeSolution,
            },
            {
                title: "Additional details on MDM",
                field: Effected.FurtherMDM,
            },
            {
                title: "Alternative Data Management solution",
                field: Effected.DMAlternative,
            },
            {
                title: "Details on additional charges",
                field: Effected.AdditionalChargeDetails,
            },
        ].filter(({ field }) => {
            const data = this.props.response.proposal.get(field);
            return data !== undefined && data !== "";
        });
        const key = `${this.props.leasing ? "WithLeasing" : ""}${this.props.vat ? "WithVat" : ""}`;
        const { response, tender } = this.props;
        const displayFavourite = () => {
            if (this.props.shared) {
                if (response.favourite) {
                    return React.createElement("div", { className: styles.shared }, "Favourite");
                }
                return;
            }
            if (this.props.comparison) {
                return (React.createElement(Button, { click: () => this.props.markFavourite(response.id), cta: response.favourite }, response.favourite ? "Favourite" : "Pick Favourite"));
            }
        };
        const scores = response[`scores${key}`];
        const meta = response[`meta${key}`];
        const { tariffCost = 0, monthlyCost = 0, oobCost = 0, totalCost, upfrontCost = 0, adjustedHardwareCredit = 0, adjustedHardwareFund = 0, hardwareFundCash = 0, savings, perLinePerMonth, mdmCost, dmCost, } = meta;
        const voiceLines = this.props.tender?.details?.voiceLines || 0;
        const dataLines = this.props.tender?.details?.dataLines || 0;
        const voiceOnlyLines = this.props.tender?.details?.voiceOnlyLines || 0;
        //components of summary
        const top = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.score },
                React.createElement("span", { className: styles.scoreTitle },
                    getBrokerName(),
                    " Score"),
                React.createElement("div", { className: `${styles.points} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement(Score, { size: "6rem", className: styles.pointsIcon }),
                    React.createElement("div", { className: styles.text },
                        React.createElement("h1", null, scores.pmScoreNormalised),
                        React.createElement("div", { className: styles.score }, "Points")))),
            React.createElement("div", { className: `${styles.topDetails} ${this.props.comparison ? styles.comparison : ""}` },
                React.createElement("div", null, this.getNetworkIcon(response.meta?.primaryPlatform)),
                React.createElement("div", { className: styles.topList },
                    React.createElement("div", null,
                        this.props.tender.details.length,
                        " months"),
                    voiceLines > 0 && (React.createElement("div", null,
                        voiceLines,
                        " voice with data lines")),
                    dataLines > 0 && (React.createElement("div", null,
                        dataLines,
                        " data only lines")),
                    voiceOnlyLines > 0 && (React.createElement("div", null,
                        voiceOnlyLines,
                        " voice only lines"))),
                React.createElement("div", { className: styles.favourite }, displayFavourite()))));
        const contractSavings = (React.createElement("div", { className: `${styles.savings} ${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
            React.createElement("div", { className: styles.left },
                React.createElement("div", { className: styles.savingsTitle },
                    "CONTRACT SAVING",
                    React.createElement(HoverTooltip, { light: true },
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                "Saving per line:",
                                " ",
                                format(meta.savingPerLine, 2)),
                            React.createElement("li", null,
                                "Saving: ",
                                format(meta.usedSaving, 2)),
                            React.createElement("li", null,
                                "Savings for dropping lines:",
                                " ",
                                format(meta.unusedSaving, 2)),
                            React.createElement("li", null,
                                "Total monthly saving:",
                                " ",
                                format(meta.monthlySavings, 2)))))),
            React.createElement("span", { className: styles.right }, format(savings, 0))));
        const vatRate = this.props.vat ? 1.2 : 1;
        const currentCost = this.props.tender.currentCost * vatRate;
        const discounts = this.props.tender.discounts;
        const netCost = currentCost - discounts;
        const currentCostPerLine = netCost /
            (this.props.tender.stats?.totalLines ||
                this.props.tender.details.totalLines ||
                1);
        const savingPercent = ((currentCostPerLine - perLinePerMonth) / currentCostPerLine) * 100;
        const avgPerLine = (React.createElement("li", { className: `${styles.highlighted} ${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
            React.createElement("span", { className: `${styles.left} ${styles.highlightedValue}` },
                "Avg. per line per month cost",
                React.createElement(HoverTooltip, { light: true }, "This is the most important number to compare supplier offers on a direct apples-for-apples comparison. It includes all costs and benefits, some visible, some\u00A0hidden")),
            React.createElement("span", { className: `${styles.right} ${styles.rightValue} ${styles.highlightedValue}` },
                format(perLinePerMonth, 2),
                React.createElement("div", { style: {
                        visibility: savingPercent > 0 ? "inherit" : "hidden",
                        fontSize: "0.8rem",
                        fontWeight: "normal",
                    } },
                    savingPercent.toFixed(1),
                    "% saving"))));
        const numbers = (avgPerLine) => (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""}` },
                "Fixed Tariff Cost",
                React.createElement(HoverTooltip, null, "The fixed contracted and billed amount per month by your network provider or supplier")),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left, onClick: () => {
                            const { shownTariff } = this.state;
                            const shownTariffTemporary = !shownTariff;
                            this.setState({
                                shownTariff: shownTariffTemporary,
                            });
                        } },
                        "Monthly contract cost",
                        React.createElement(Arrow, { size: "1rem", className: styles.arrow, rotate: this.state.shownTariff ? 90 : 0 })),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(tariffCost, 2))),
                React.createElement("div", { className: `${this.state.shownTariff ? "" : styles.closed} ${styles.bodyWrapper}` },
                    React.createElement(TariffTable, { data: response.meta.tariffComponents })),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Annual contract cost",
                        React.createElement(HoverTooltip, null, "For annual budgeting purposes if required")),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(tariffCost * 12, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Total contract cost",
                        React.createElement(HoverTooltip, null,
                            this.props.tender.details.length,
                            " months *",
                            " ",
                            format(tariffCost, 2))),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(tariffCost * this.props.tender.details.length, 2)))),
            React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""}` },
                "Variable Cost",
                " ",
                React.createElement(HoverTooltip, null, "Charges outside your tariff allowance")),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Expected variable cost pm",
                        React.createElement(OpenInNew, { className: styles.oobOpen, onClick: this.openOOB })),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue} ${styles.bundlesButton}`, onClick: this.openOOB }, format(oobCost, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left }, "Expected variable cost over term"),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(oobCost * tender.details.length, 2)))),
            React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""}` }, "Total Anticipated Cost"),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Monthly Air Time Credit",
                        React.createElement(HoverTooltip, null, "Discount offered by the supplier")),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(-response.proposal.get(Effected.Discount) || 0, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left }, "Total monthly cost inc. variable cost"),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(monthlyCost, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left }, "Total annual cost inc. variable cost"),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(monthlyCost * 12, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Total fixed and Charges outside your",
                        React.createElement("br", null),
                        " allowance cost over term"),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(totalCost, 2)))),
            React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""}` },
                "Total Cost of Ownership",
                React.createElement(HoverTooltip, null, "The real cost to companies after all the visible fixed cost and non transparent anticipated charges outside your tariff allowances plus all rebates and hardware fund is added back into the price")),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Upfront cost",
                        React.createElement(HoverTooltip, null, "Cost of buying handsets and sims. This is usually covered by the hardware fund.")),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(upfrontCost, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Hardware fund",
                        React.createElement(HoverTooltip, null,
                            React.createElement("ul", null,
                                React.createElement("li", null,
                                    "Hardware fund available as cash:",
                                    " ",
                                    format(hardwareFundCash, 2)),
                                React.createElement("li", null,
                                    "Hardware fund available as credit:",
                                    " ",
                                    format(adjustedHardwareCredit, 2))))),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(adjustedHardwareFund, 2))),
                React.createElement("li", { className: `${styles.row} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: styles.left },
                        "Net contract cost",
                        React.createElement(HoverTooltip, null, "Total fixed and variable charges, plus upfront cost")),
                    React.createElement("span", { className: `${styles.right} ${styles.rightValue}` }, format(response.meta.totalNetCostOfOwnership, 2))),
                avgPerLine)));
        const covered = CalculateUsageCovered(tender, proposal);
        const groupedTariffSummary = response.meta.groupedTariffSummary || [];
        const [availableBundles, usedBundles] = (proposal.bundles || []).reduce((array, bundle) => {
            const { applyToRows } = bundle;
            const hasUsage = applyToRows.some((key) => {
                return (covered[key]?.used || 0) > 0;
            })
                ? 1
                : 0;
            array[hasUsage].push({
                ...Factory.Bundle(bundle).data,
                used: hasUsage * bundle.numBundles,
            });
            return array;
        }, [[], []]);
        const keyPoints = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${styles.keyPointsWrapper} ${this.props.comparison ? styles.comparison : ""} ${this.state.keyPoints ? styles.open : ""}` },
                React.createElement(ColouredBorderBox, null,
                    React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""} ${styles.keyPointsTitle}`, onClick: this.toggleKeyPoints },
                        "Key Points",
                        this.props.comparison && (React.createElement(Arrow, { size: "0.8em", rotate: this.state.keyPoints ? 90 : 0 }))),
                    React.createElement("div", { className: `${accordionStyles.wrapper} ${accordionStyles.keyPoints}` },
                        React.createElement("ul", { className: `${styles.keyPoints} ${this.state.keyPoints
                                ? accordionStyles.altBodyWrapper
                                : accordionStyles.bodyWrapper} ${this.state.keyPoints
                                ? ""
                                : accordionStyles.closed} ${this.props.comparison
                                ? styles.comparison
                                : styles.noBorder}` },
                            React.createElement("li", { className: accordionStyles.subitem }, tender.details.charitySocialEnterprise && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.subtitle },
                                    "Social enterprise supplier",
                                    React.createElement(HoverTooltip, null,
                                        React.createElement("div", null, "Full - Is limited by guarantee and asset locked"),
                                        React.createElement("div", null, "Partial - Is limited by shares or guarantee but NOT asset locked"))),
                                React.createElement("div", { className: accordionStyles.value }, this.props.response
                                    .assetLock ? (this.props.response
                                    .limitedGuarantee ===
                                    SocialEnterpriseLimitation.GUARANTEE ? (React.createElement(React.Fragment, null, "Full")) : (React.createElement(React.Fragment, null, "Limited"))) : (React.createElement(React.Fragment, null, "No")))))),
                            React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Tariff name"),
                                React.createElement("div", { className: accordionStyles.value }, response.proposal.get(Effected.PlanName))),
                            React.createElement("li", { className: `${accordionStyles.subitem}` },
                                React.createElement("div", { className: styles.subtitle },
                                    "Co-term contract",
                                    response.proposal.get(Effected.TimeLeftTerm) ===
                                        TermLength.Additional_lines_wrapped_into_next_upgrade_period && (React.createElement(HoverTooltip, null, "If partial, additional lines will be wrapped into next upgrade period"))),
                                React.createElement("div", { className: `${accordionStyles.value} ${styles.value}` }, response.proposal.get(Effected.AdditionalLinesCoTerminus) === false
                                    ? "No"
                                    : response.proposal.get(Effected.TimeLeftTerm) ===
                                        TermLength.Additional_lines_wrapped_into_next_upgrade_period
                                        ? "Partial"
                                        : "Full")),
                            Object.keys(this.props.tender.ofcom).length >
                                0 && (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Signal Strength"),
                                React.createElement("div", { className: accordionStyles.value },
                                    React.createElement("span", { className: styles.bundlesButton, onClick: () => {
                                            this.openSignalPopup();
                                        } }, "View")))),
                            !tender.noMDM && (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Cost per line per month for MDM"),
                                React.createElement("div", { className: accordionStyles.value }, format(mdmCost)))),
                            !tender.noDM && (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Cost per line per month for Data Management"),
                                React.createElement("div", { className: accordionStyles.value }, format(dmCost)))),
                            usedBundles.length > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Bundles applied"),
                                React.createElement("div", { className: accordionStyles.value },
                                    React.createElement("span", { className: styles.bundlesButton, onClick: () => {
                                            this.openBundles(usedBundles);
                                        } }, "Click here")))),
                            availableBundles.length > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Bundles available"),
                                React.createElement("div", { className: accordionStyles.value },
                                    React.createElement("span", { className: styles.bundlesButton, onClick: () => {
                                            this.openBundles(availableBundles);
                                        } }, "Click here")))),
                            proposal.minimumSpend !== undefined &&
                                proposal.minimumSpend !== 0 ? (React.createElement("li", { className: accordionStyles.subitem },
                                React.createElement("div", null, "Minimum Contract Spend"),
                                React.createElement("div", { className: accordionStyles.value }, format(proposal.minimumSpend, 0)))) : (React.createElement(React.Fragment, null)),
                            groupedTariffSummary.map((section, index) => {
                                return (React.createElement(Fragment, { key: index },
                                    React.createElement("hr", null),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle }, "Network"),
                                        React.createElement("div", { className: accordionStyles.value }, getIconFromNetwork(platformToNetwork(section.platform)))),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle },
                                            "Tariff type",
                                            React.createElement(HoverTooltip, null, section.type.includes("Individual") ? (React.createElement(React.Fragment, null, "Individual Data. Each line gets its own data allowance, additional charges will be added if this is exceeded")) : section.type.includes("Shared") ? (React.createElement(React.Fragment, null, "Shared Data. A set amount of data is shared between all lines. Adding new lines does not increase the amount of data.")) : section.type.includes("Pooled") &&
                                                !section.additionalDataNoNewLine &&
                                                !section.additionalDataAuto ? (React.createElement(React.Fragment, null, "Pooled Data. Each line has its own data allowance. This is combined with all lines into a shared pool of data that everyone can use. Once the pool is exhausted additional charges may be incurred. Adding new lines increases the pooled data. It may be possible to increase the pool without adding additional lines")) : section.type.includes("Aggregated") &&
                                                !section.additionalDataNoNewLine &&
                                                section.additionalDataAuto ? (React.createElement(React.Fragment, null, "Aggregated Data. Each line gets it own data allowance, once exceeded it will start to use available allowance from other lines in the pool. Adding new lines increases the amount of data. If the total pool is exceeded for 3 months in a row, all lines will be automatically increased to the next data tier and cost. If then, data usage falls over the 3 following months, all lines' data will reduce to the previous level. Data amount cannot be reduced below the initial contracted tier")) : section.type.includes("Aggregated") &&
                                                !section.additionalDataNoNewLine &&
                                                !section.additionalDataAuto ? (React.createElement(React.Fragment, null, "Aggregated Data. Each line has its own data allowance. This is combined with all lines into a shared pool of data that everyone can use. Once the pool is exhausted additional charges may be incurred. Adding new lines increases the pooled data. It may be possible to increase the pool without adding additional lines")) : section.type.includes("Pooled") &&
                                                section.additionalDataNoNewLine ? (React.createElement(React.Fragment, null, "Pooled Data. Each line has its own data allowance. This is combined with all lines into a shared pool of data that everyone can use. Once the pool is exhausted additional charges may be incurred. Adding new lines increases the pooled data. It is not possible to increase the pool without adding additional lines")) : section.type.includes("Aggregated") &&
                                                section.additionalDataNoNewLine ? (React.createElement(React.Fragment, null, "Aggregated Data. Each line has its own data allowance. This is combined with all lines into a shared pool of data that everyone can use. Once the pool is exhausted additional charges may be incurred. Adding new lines increases the pooled data. It is not possible to increase the pool without adding additional lines")) : (React.createElement(React.Fragment, null, "This is the type of tariff offered to you")))),
                                        React.createElement("div", { className: accordionStyles.value }, section.type)),
                                    section.lines > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle }, "Voice lines"),
                                        React.createElement("div", { className: accordionStyles.value }, section.lines))),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", null, "Cost for a future additional voice line"),
                                        React.createElement("div", { className: accordionStyles.value }, format(section.additionalLineCost))),
                                    section.dataLines > 0 && (React.createElement(React.Fragment, null,
                                        " ",
                                        React.createElement("li", { className: accordionStyles.subitem },
                                            React.createElement("div", { className: styles.subtitle },
                                                "Data lines",
                                                " ",
                                                React.createElement(HoverTooltip, null, "These line can only use data, they are not able to send / receive calls or tests")),
                                            React.createElement("div", { className: accordionStyles.value }, section.dataLines)),
                                        React.createElement("li", { className: accordionStyles.subitem },
                                            React.createElement("div", null, "Cost for a future additional data only line"),
                                            React.createElement("div", { className: accordionStyles.value }, format(section.additionalDataLineCost))))),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle },
                                            "Data allowance",
                                            (section.type.includes("Pooled") ||
                                                section.type.includes("Aggregated")) &&
                                                !section.additionalDataNoNewLine ? (React.createElement(HoverTooltip, null,
                                                section.dataAllowance,
                                                "GB per line *",
                                                " ",
                                                (section.lines ||
                                                    0) +
                                                    (section.dataLines ||
                                                        0),
                                                " ",
                                                "lines")) : (React.createElement(React.Fragment, null))),
                                        React.createElement("div", { className: accordionStyles.value }, (section.type.includes("Pooled") ||
                                            section.type.includes("Aggregated")) &&
                                            !section.additionalDataNoNewLine ? (React.createElement(React.Fragment, null, `${Number(section.dataAllowance) *
                                            ((section.lines ||
                                                0) +
                                                (section.dataLines ||
                                                    0))} GB`)) : (React.createElement(React.Fragment, null, `${section.dataAllowance} GB`)))),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle },
                                            "Cost for ",
                                            React.createElement("b", null, "1GB"),
                                            " of additional Data",
                                            React.createElement(HoverTooltip, null, section.dataAllowance ===
                                                "unlimited" ? (React.createElement(React.Fragment, null, "Extra data not required on unlimited plans")) : (React.createElement(React.Fragment, null, "This enables apples-for-apples comparison")))),
                                        React.createElement("div", { className: accordionStyles.value }, format(section.costPerGB))),
                                    React.createElement("li", { className: accordionStyles.subitem },
                                        React.createElement("div", { className: styles.subtitle },
                                            "Minimum cost for a data increase",
                                            React.createElement(HoverTooltip, null, section.dataAllowance ===
                                                "unlimited" ? (React.createElement(React.Fragment, null, "Extra data not required on unlimited plans")) : (section.type.includes("Pooled") ||
                                                section.type.includes("Aggregated")) &&
                                                !section.additionalDataNoNewLine &&
                                                section.additionalDataAuto ? (React.createElement(React.Fragment, null,
                                                "This cost equates to",
                                                " ",
                                                section.additionalDataMinQty *
                                                    (section.lines +
                                                        section.dataLines),
                                                "GB of additional data",
                                                React.createElement("br", null),
                                                section.additionalDataMinQty,
                                                "GB per line *",
                                                " ",
                                                section.lines +
                                                    section.dataLines,
                                                " ",
                                                "lines")) : section.additionalDataNoNewLine ? (React.createElement(React.Fragment, null, "Additional data is only available when adding new lines")) : (React.createElement(React.Fragment, null,
                                                section.additionalDataMinQty,
                                                "GB min increase")))),
                                        React.createElement("div", { className: accordionStyles.value }, format(section.additionalDataCost)))));
                            })))))));
        const accordion = (React.createElement(React.Fragment, null,
            React.createElement(Accordion, { comparison: this.props.comparison, data: response, devices: this.props.devices, tender: this.props.tender }),
            React.createElement(Button, { className: styles.additionalDetails, click: this.openAdditionalInfo, disabled: details.length === 0 }, "VIEW ADDITIONAL DETAILS")));
        const health = (React.createElement("div", { className: `${styles.healthWrapper} ${this.props.comparison ? styles.comparison : ""}` },
            React.createElement("div", { className: `${styles.title} ${this.props.comparison ? styles.comparison : ""}` },
                "Mobile Contract Health Scores",
                React.createElement(HoverTooltip, null,
                    React.createElement("div", { className: `${styles.tooltip} ${this.props.comparison
                            ? styles.comparisonTooltip
                            : ""}` },
                        "Great",
                        React.createElement(HealthScore, { place: "veryGood", small: true }),
                        React.createElement(HealthScore, { place: "good", small: true }),
                        React.createElement(HealthScore, { place: "medium", small: true }),
                        React.createElement(HealthScore, { place: "bad", small: true }),
                        React.createElement(HealthScore, { place: "veryBad", small: true }),
                        "Poor"))),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", { className: `${styles.row} ${styles.healthRow} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: `${styles.left} ${styles.healthLeft}` },
                        React.createElement(Price, { className: styles.icon, size: "1.8em" }),
                        "Value"),
                    React.createElement(HealthScore, { place: scores.priceScoreColor, score: scores.priceScoreNormalised, small: true })),
                React.createElement("li", { className: `${styles.row} ${styles.healthRow} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: `${styles.left} ${styles.healthLeft}` },
                        React.createElement(Tariff, { className: styles.icon, size: "1.8em" }),
                        " ",
                        "Tariff"),
                    React.createElement(HealthScore, { place: scores.tariffScoreColor, score: scores.tariffScoreNormalised, small: true })),
                React.createElement("li", { className: `${styles.row} ${styles.healthRow} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: `${styles.left} ${styles.healthLeft}` },
                        React.createElement(CustomerService, { className: styles.icon, size: "1.8em" }),
                        " ",
                        "Customer Service",
                        scores.lowMarginWarning && React.createElement(Warning, null)),
                    React.createElement(HealthScore, { place: scores.csScoreColor, score: scores.csScoreNormalised, small: true })),
                React.createElement("li", { className: `${styles.row} ${styles.healthRow} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: `${styles.left} ${styles.healthLeft}` },
                        React.createElement(Signal, { className: styles.icon, size: "1.8em" }),
                        "Signal"),
                    React.createElement(HealthScore, { place: scores.signalScoreColor, score: scores.signalScoreNormalised, small: true })),
                React.createElement("li", { className: `${styles.row} ${styles.healthRow} ${this.props.comparison ? styles.comparison : ""}` },
                    React.createElement("span", { className: `${styles.left} ${styles.healthLeft}` },
                        React.createElement(Convenience, { className: styles.icon, size: "1.8em" }),
                        "Convenience"),
                    React.createElement(HealthScore, { place: scores.convenienceScoreColor, score: scores.convenienceScoreNormalised, small: true })))));
        const content = this.props.comparison ? (React.createElement(React.Fragment, null,
            top,
            contractSavings,
            avgPerLine,
            health,
            keyPoints,
            numbers(),
            accordion)) : (React.createElement(React.Fragment, null,
            top,
            contractSavings,
            numbers(avgPerLine),
            keyPoints,
            accordion,
            health));
        return (React.createElement("div", { className: `${styles.content} ${this.props.comparison && styles.comparison}` },
            scores.pmScoreRank === 1 && (React.createElement("div", { className: styles.topRated },
                React.createElement(TopRated, { className: styles.topIcon }))),
            this.props.close && (React.createElement("div", { className: styles.header },
                React.createElement("span", { className: styles.exit },
                    React.createElement(Close, { onClick: this.props.close, color: "#58585a", size: "0.75rem", className: styles.button })))),
            (this.props.adminUser ||
                this.props.tender.showSupplierNames) && (React.createElement("div", { className: styles.name }, this.props.response.supplierName)),
            content,
            this.props.adminUser && (React.createElement("div", { className: styles.viewButton },
                this.props.response.supplierName,
                React.createElement(ButtonLink, { className: styles.squareButton, to: GetPath(SUPPLIER_RESPONSE, this.props.response.supplierId, this.props.response.id) }, "VIEW SUPPLIER RESPONSE"))),
            !this.props.shared && (React.createElement("div", { className: styles.selectButton },
                React.createElement(Button, { className: styles.squareButtonSize, click: () => this.props.openPopup(this.props.response.id), cta: true }, "SELECT THIS SUPPLIER"))),
            React.createElement(AdditionalInfo, { open: this.state.additionalInfoOpen, onClose: this.closeAdditionalInfo, proposal: this.props.response.proposal, details: details }),
            React.createElement(OOBCosts, { oobCost: oobCost, proposal: proposal, tender: tender, onClose: this.closeOOB, openBundles: () => {
                    this.closeOOB();
                    this.openBundles(usedBundles);
                }, open: this.state.openOOB, comparison: this.props.comparison }),
            React.createElement(Bundles, { onClose: this.closeBundles, openOOB: () => {
                    this.closeBundles();
                    this.openOOB();
                }, bundles: this.state.openBundles, comparison: this.props.comparison }),
            React.createElement(OfcomPopup, { open: this.state.openOfcom, onClose: this.closeOfcom, tender: this.props.tender, comparison: this.props.comparison, tariffComponents: this.props.response.meta?.tariffComponents, notify: this.props.notify, sharedComparison: this.props.comparison && this.props.shared })));
    }
}
