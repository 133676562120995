import axios from "axios";
import getSubdomain from "../Helpers/GetSubdomain";
export const subdomain = getSubdomain();
const api = axios.create({
    baseURL: API_DOMAIN,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Sub-Domain": subdomain,
    },
});
api.interceptors.request.use((config) => {
    // return authorization header with jwt token
    const token = localStorage.getItem("token");
    const mfa = localStorage.getItem("mfa");
    if (token) {
        config.headers.Authorization =
            "Bearer " + [token, mfa].filter(Boolean).join(":::");
    }
    return config;
});
export default api;
