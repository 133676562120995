// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--T3Hgq{position:relative;display:inline-block;margin-left:2px}.wrapper--T3Hgq .tooltip--OMBbq{position:absolute;z-index:99999;width:20rem;min-height:1rem;color:var(--text-color);font-weight:normal;font-size:.75rem;line-height:.9rem;white-space:pre-line;pointer-events:none}.wrapper--T3Hgq .tooltip--OMBbq .text--j0e0X{background-color:#fff;border-radius:.2em;box-shadow:0 1px 5px 2px rgba(0,0,0,.2);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/gui/Components/HoverTooltip/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,iBAAiB,CAAC,oBAAoB,CAAC,eAAe,CAAC,gCAAkB,iBAAiB,CAAC,aAAa,CAAC,WAAW,CAAC,eAAe,CAAC,uBAAuB,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,oBAAoB,CAAC,mBAAmB,CAAC,6CAAwB,qBAAqB,CAAC,kBAAkB,CAAC,uCAAuC,CAAC,2EAA2E","sourcesContent":[".wrapper{position:relative;display:inline-block;margin-left:2px}.wrapper .tooltip{position:absolute;z-index:99999;width:20rem;min-height:1rem;color:var(--text-color);font-weight:normal;font-size:.75rem;line-height:.9rem;white-space:pre-line;pointer-events:none}.wrapper .tooltip .text{background-color:#fff;border-radius:.2em;box-shadow:0 1px 5px 2px rgba(0,0,0,.2);padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--T3Hgq",
	"tooltip": "tooltip--OMBbq",
	"text": "text--j0e0X"
};
export default ___CSS_LOADER_EXPORT___;
