import React from "react";
import Icon from "@Components/Icons/Tooltip";
import Warning from "@Components/Icons/Warning";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";
export default class HoverTooltip extends React.Component {
    tooltipRef;
    constructor(props) {
        super(props);
        this.state = {
            tooltipPosition: "top",
            visible: false,
        };
        this.tooltipRef = React.createRef();
    }
    componentDidMount() {
        this.adjustTooltipPosition();
    }
    adjustTooltipPosition() {
        const rect = this.tooltipRef.current?.getBoundingClientRect();
        if (rect) {
            if (rect.bottom > window.innerHeight) {
                this.setState({ tooltipPosition: "top" });
            }
            else if (rect.top < 0) {
                this.setState({ tooltipPosition: "bottom" });
            }
            else if (rect.right > window.innerWidth) {
                this.setState({ tooltipPosition: "left" });
            }
            else if (rect.left < 0) {
                this.setState({ tooltipPosition: "right" });
            }
        }
    }
    getTooltipStyles() {
        switch (this.state.tooltipPosition) {
            case "top":
                return {
                    bottom: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    marginBottom: "5px",
                };
            case "right":
                return {
                    left: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    marginLeft: "5px",
                };
            case "bottom":
                return {
                    top: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    marginTop: "5px",
                };
            case "left":
                return {
                    right: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    marginRight: "5px",
                };
            default:
                return {};
        }
    }
    handleHover = () => {
        this.setState({ visible: true });
    };
    handleOut = () => {
        this.setState({ visible: false });
    };
    renderTooltip = () => {
        if (!this.tooltipRef.current)
            return null;
        const { top, left, height } = this.tooltipRef.current.getBoundingClientRect();
        const style = {
            ...this.getTooltipStyles(),
            position: "absolute",
            top: top + window.scrollY + height,
            left: left + window.scrollX + height,
            zIndex: 9999,
        };
        return ReactDOM.createPortal(React.createElement("span", { className: `${styles.tooltip} ${this.props.className}`, style: style },
            React.createElement("div", { className: this.props.className, style: {
                    backgroundColor: "white",
                    maxWidth: "20em",
                    minWidth: "10em",
                    minHeight: "1em",
                    fontSize: "0.75rem",
                    lineHeight: "0.9rem",
                    borderRadius: "0.2em",
                    boxShadow: "0 1px 5px 2px rgba(0, 0, 0, 0.2)",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    ...this.props.style,
                } }, this.props.children)), document.body);
    };
    render() {
        return (React.createElement("span", { className: `${styles.wrapper} ${this.props.iconClassName}`, ref: this.tooltipRef },
            this.props.warning !== true && (React.createElement(Icon, { onMouseOver: this.handleHover, onMouseOut: this.handleOut, size: "12", light: this.props.light })),
            this.props.warning === true && (React.createElement(Warning, { onMouseOver: this.handleHover, onMouseOut: this.handleOut, size: "1.2rem" })),
            this.state.visible && this.renderTooltip()));
    }
}
